<template>
  <div class="container">
    <Info :name="info.name" :post="info.postName"></Info>
    <van-form @submit="onSubmit" class="form">
      <van-field
          type="number"
          v-model="params.bankNo"
          name="bankNo"
          label="银行卡账号"
          placeholder="请输入银行卡账号"
      />
      <van-field
          v-model="params.bankAccount"
          name="bankAccount"
          label="银行卡账户"
          placeholder="请输入民生银行卡账户名"
      />
      <van-field
          v-model="params.license_plate_no"
          name="license_plate_no"
          label="自驾车牌号"
          placeholder="请输入自驾车牌号"
      />
      <van-field name="uploader" style="flex-wrap: wrap;" label-width="100%" label="文件上传">
        <template #input>
          <van-uploader name="idcard" :after-read="afterRead" @delete="del" :max-count="2" v-model="idFile"/>
        </template>
        <template #label>
          <div class="tit">
            <div class="left">
              <p>身份证</p>
              <span>（请务必按照先头像面后国徽面顺序上传）</span>
            </div>
            <p class="right">{{ UploadIdFile.length }}/2</p>
          </div>
        </template>
      </van-field>
      <van-field name="uploader" style="flex-wrap: wrap;" label-width="100%" label="">
        <template #input>
          <van-uploader name="xueli" :max-count="3" :after-read="afterRead" @delete="del" v-model="xueliFile"/>
        </template>
        <template #label>
          <div class="tit">
            <div class="left">
              <p>学历证明</p>
            </div>
            <p class="right">{{ UploadXueliFile.length }}/3</p>
          </div>
        </template>
      </van-field>
      <van-field name="uploader" style="flex-wrap: wrap;" label-width="100%" label="">
        <template #input>
          <van-uploader name="lizhi" :max-count="3" :after-read="afterRead" @delete="del" v-model="lizhiFile"/>
        </template>
        <template #label>
          <div class="tit">
            <div class="left">
              <p>离职证明</p>
            </div>
            <p class="right">{{ UploadLizhiFile.length }}/3</p>
          </div>
        </template>
      </van-field>
      <van-field name="uploader" style="flex-wrap: wrap;" label-width="100%" label="学历证明">
        <template #input>
          <van-uploader name="yigan" :max-count="3" :after-read="afterRead" @delete="del" v-model="yiganFile"/>
        </template>
        <template #label>
          <div class="tit">
            <div class="left">
              <p>乙肝五项检查报告</p>
            </div>
            <p class="right">{{ UploadYiganFile.length }}/3</p>
          </div>
        </template>
      </van-field>
      <div style="margin: 16px;">
        <van-button :disabled="re_empstatus" style="background-color: #02DBCA;border-color: #02DBCA;" block type="info" native-type="submit">
          提交
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import Info from '../components/PersonalInfo'
import {GetUserStatus} from "@/api/base";
import {SubmitEntryData} from "@/api/entry";
import axios from "axios";

export default {
  name: "EntryRegistration",
  components: {
    Info
  },
  created() {
    this.$store.dispatch('changeLocation', '入职资料')
    this.fetchData()
  },
  data() {
    return {
      username: '',
      password: '',
      uploader: [],
      re_empstatus: false,
      info: [],
      idFile: [],
      UploadIdFile: [],
      lizhiFile: [],
      UploadLizhiFile: [],
      xueliFile: [],
      UploadXueliFile: [],
      yiganFile: [],
      UploadYiganFile: [],
      params: {
        bankAccount: "",   //银行卡户
        bankNo: "",    //银行卡账号
        license_plate_no: "",   //车牌号
        idFiles: "",   //身份证图片
        xueliFiles: "",   //学历图片
        lizhiFiles: "",   //离职图片
        yiganFiles: "",   //乙肝五项图片
        openId: this.$store.state.info.openId
      }
      // uploader: [{ url: 'https://img.yzcdn.cn/vant/leaf.jpg' }],
    };
  },
  methods: {
    async afterRead(file, info) {
      console.log(file, info);
      let loading = this.$toast.loading({
        message: '上传中...',
        forbidClick: true,
        duration: 0
      });
      // 此时可以自行将文件上传至服务器
      let formData = new FormData();
      let files = await this.$image.compressImg(file.file)
      formData.append('file', files);
      let requestUrl = info.name =='idcard' ? 'https://xy.eainger.cn/api/XmhHr/UploadIDImg' : 'https://xy.eainger.cn/api/XmhHr/UploadImg';
      let url = '';
      let res = await axios.post(requestUrl,formData).catch(err=>{
        loading.clear();
        this.$toast('上传出现问题');
        if(info.name == 'idcard'){
          this.idFile.splice(this.UploadIdFile.length,1)
        }
        if(info.name == 'xueli'){
          this.xueliFile.splice(this.UploadXueliFile.length,1)
        }
        if(info.name == 'yigan'){
          this.yiganFile.splice(this.UploadYiganFile.length,1)
        }
        if(info.name == 'lizhi'){
          this.lizhiFile.splice(this.UploadLizhiFile.length,1)
        }
        return
      })
      console.log(res.data);

      if(!res.data.result){
        loading.clear();
        this.$toast(res.data.msg);
        if(info.name == 'idcard'){
          this.idFile.splice(this.UploadIdFile.length,1)
        }
        if(info.name == 'xueli'){
          this.xueliFile.splice(this.UploadXueliFile.length,1)
        }
        if(info.name == 'yigan'){
          this.yiganFile.splice(this.UploadYiganFile.length,1)
        }
        if(info.name == 'lizhi'){
          this.lizhiFile.splice(this.UploadLizhiFile.length,1)
        }
        console.log(this.idFile);
        return
      }
      url = res.data.filePath
      console.log(info,url);
      switch (info.name) {
        case 'idcard':
          this.UploadIdFile.push(url)
          break;
        case 'xueli':
          this.UploadXueliFile.push(url)
          break;
        case 'yigan':
          this.UploadYiganFile.push(url)
          break;
        case 'lizhi':
          this.UploadLizhiFile.push(url)
          break;
      }
      loading.clear();
    },
    onSubmit(values) {
      console.log(this.idFile);
      if(this.UploadIdFile.length != 2){
        this.$toast('请上传身份证前面及后面两张图片')
        return
      }
      // if(this.UploadXueliFile.length != 3){
      //   this.$toast('请上传3张学历证明图片')
      //   return
      // }
      // if(this.UploadYiganFile.length != 3){
      //   this.$toast('请上传3张乙肝五项体检图片')
      //   return
      // }
      this.params.idFiles = this.UploadIdFile.toString()
      this.params.xueliFiles = this.UploadXueliFile.toString()
      this.params.yiganFiles = this.UploadYiganFile.toString()
      this.params.yiganFiles = this.UploadYiganFile.toString()
      this.params.lizhiFiles = this.UploadLizhiFile.toString()
      console.log(this.params);
      SubmitEntryData(this.params).then(res=>{
        console.log(res);
        if(res.errcode == 200){
          this.$toast('提交成功');
          setTimeout(()=>{
            this.$router.push({
              name: 'Search'
            })
          },1000)
        }else{
          this.$toast(res.message)
        }
      })
    },
    del(file, info) {
      switch (info.name) {
        case 'idcard':
          this.UploadIdFile.splice(info.index, 1)
          break;
        case 'xueli':
          this.UploadXueliFile.splice(info.index, 1)
          break;
        case 'yigan':
          this.UploadYiganFile.splice(info.index, 1)
          break;
      }
    },
    fetchData() {
      GetUserStatus({openId: this.$store.state.info.openId}).then(res => {
        console.log(res);
        if(res.row == null || res.errcode == 2 || res.row.re_empstatus !=1){ //不能提交入职资料
          this.$toast({message:'已经交入职资料或未完成面试流程，不能提交入职资料~',duration: 2500})
          this.re_empstatus = true;
        }
        if(res.row !== null){
          this.info = res.row;
        }else{
          this.info.name = this.$store.state.info.nickName
          this.info.postName = res.message;
        }
      })
    }
  },
}
</script>

<style scoped lang="scss">
.container {
  padding-top: 5px;

  .tit {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    .left {
      display: flex;
      align-items: center;
      p{
        margin: 0;
      }
      span {
        font-size: 11px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #FF2A00;
        line-height: 1.5;
        margin-left: 3px;
      }
    }
    .right{
      margin: 0;
    }
  }

  .form {
    margin-top: 15px;
  }
}

</style>
